.fieldSetHeaderContainer{
    background-color: white;
    width: 100%;
    padding: 30px 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-transform: uppercase;
}

.fieldSetMainContainer{
    background-color: white;
    width: 96%;
    /* margin: 10px 30px; */
    padding: 30px 0px;
    border-radius: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-right: 30px;
    margin-left: 30px;
}

.fieldSetItemContainer{
    padding: 2px 20px;
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    border-radius: 10px;
    width: 40%;
    cursor: pointer;
}

.get-details-btn {
    background-color: #044d81;
    color: white;
    border-radius: 10px;
    height: 40px;
    padding: 0px 30px;
  }