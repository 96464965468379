.css-ahj2mt-MuiTypography-root {
  font-weight: bold !important;
}

.giveBorderLeft{
    border-left: 1px solid rgba(0,0,0,0.15);
}

.giveShadows{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.fieldSetHeaderContainer{
    background-color: white;
    width: 100%;
    padding: 30px 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-transform: uppercase;
}

.fieldSetMainContainer{
    background-color: white;
    width: 100%;
    /* margin: 10px 30px; */
    padding: 30px 0px;
    border-radius: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-right: 30px;
    margin-left: 30px;
}

.fieldSetItemContainer{
    padding: 2px 20px;
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    border-radius: 10px;
    width: 40%;
    cursor: pointer;
}