.sideBarNavLink {
  text-decoration: none !important;
}

.sideBarNavLink span {
  color: #22003b;
  opacity: 0.65;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.03em;
}

.sideBarNavLink img {
  width: 25px;
  object-fit: contain;
}
