.categroy-modal .addlang-modalBody {
  /* / width: 200px; / */
  /* height: 368px;
      padding: 50px 50px; */
  /* / height: 256px; / */
  height: auto;
  padding: 10px 50px;
}

.categroy-modal .innerContainer {
  text-align: center;
}
.categroy-modal input {
  /* border-color: #dce0eb;
      width: 240px;
      height: 38px;
      padding: 2px 12px;
      border-radius: 8px;
      font-size: 15px;
      margin-top: 10px; */

  margin: 15px 0;
  padding: 13px 10px;
  width: 80%;
  outline: none;
  border: 1px solid #bbb;
  border-radius: 8px;
  display: inline-block;
  box-sizing: border-box;
  transition: 0.2s ease all;
}
.categroy-modal h2 {
  /* / margin-bottom: 28px; / */
  color: #fff;
  font-size: 28px;
  font-weight: 400;
}

.categroy-modal .btn-save {
  color: #fff;
  background-color: #044d81;
  padding: 12px 22px;
  /* / width: 299px; / */
  width: 148px;
  border-radius: 8px;
}
.categroy-modal .btn-cancel {
  color: #044d81;
  background-color: #eceff1;
  padding: 12px 22px;
  border: 1px solid #044d81;
  /* / width: 299px; / */
  width: 148px;
  border-radius: 8px;
}
.categroy-modal .btn-save:hover {
  color: white;
  background-color: #044d81;
}

.categroy-modal .btn-save:active {
  color: rgb(19, 19, 19);
  background-color: #abc7db;
}

.categroy-modal textarea {
  margin: 15px 0;
  padding: 13px 10px;
  width: 80%;
  outline: none;
  border: 1px solid #bbb;
  border-radius: 8px;
  display: inline-block;
  box-sizing: border-box;
  transition: 0.2s ease all;
}

.modal-Header {
  height: 90px;
  background-color: #044d81;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: -1px;
  align-items: center;
  border-radius: 18px;
}

.categroy-modal .addlang-modalBody {
  height: auto;
  padding: 10px 50px;
}

.categroy-modal .innerContainer {
  text-align: center;
}

.categroy-modal .modal-content {
  border-radius: 20px !important;
}











.categroy-modal .icon-upload-avatar {
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.categroy-modal .icon-upload-avatar > input[type="file"] {
  position: absolute;
  top: -16px;
  width: 40%;
  height: 100%;
  opacity: 0;
}
.categroy-modal .icon-upload-avatar > span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.categroy-modal .icon-upload-avatar > img {
  width: 40%;
}