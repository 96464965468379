.blocked-users .block-btn {
  width: 120px;
  background-color: #044d81;
  color: white;
  border-radius: 7px;
  flex-wrap: wrap;
  padding: 9px;
}

.blocked-users .block-btn:hover {
  color: white;
}

.input-city {
    padding-left:20px;
  width: 300px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  font-size: 17px;
}

.update-city-modal.modal-content {
  width: 500px;
  background-color: #044d81 !important;
}

.update-city-modal-body {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #044d81;
  padding: 10px;
}

.update-city-modal .btn-save {
  color: #044d81;
  background-color: white;
  padding: 12px 22px;
  width: 170px;
  border: 1px solid #044d81 !important;
  border-radius: 20px;
}
.update-city-modal .btn-save:hover {
  border: 1px solid #044d81 !important;
  background-color: white;
}

.update-city-modal .btn-save:active {
  color: rgb(19, 19, 19);
  background-color: white;
  border: 1px solid #044d81 !important;
}

.confirmText {
  color: white;
}

.input-city:focus {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.4);
}

.saveBtn-Container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  background-color: #044d81 !important;
}
