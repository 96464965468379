/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

@font-face {
  font-family: Poppins-Regular;
  src: url("../fonts/poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: Poppins-Medium;
  src: url("../fonts/poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: Poppins-Bold;
  src: url("../fonts/poppins/Poppins-Bold.ttf");
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url("../fonts/poppins/Poppins-SemiBold.ttf");
}

/*//////////////////////////////////////////////////////////////////
  [ RESTYLE TAG ]*/

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  font-family: Poppins-Regular, sans-serif;
  overflow-x: hidden;
}

/*---------------------------------------------*/
a {
  font-family: Poppins-Regular;
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
  color: #a64bf4;
}

/*---------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

p {
  font-family: Poppins-Regular;
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}

/*---------------------------------------------*/
input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus,
input:focus {
  border-color: transparent !important;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}
input:focus:-moz-placeholder {
  color: transparent;
}
input:focus::-moz-placeholder {
  color: transparent;
}
input:focus:-ms-input-placeholder {
  color: transparent;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}
textarea:focus:-moz-placeholder {
  color: transparent;
}
textarea:focus::-moz-placeholder {
  color: transparent;
}
textarea:focus:-ms-input-placeholder {
  color: transparent;
}

input::-webkit-input-placeholder {
  color: #adadad;
}
input:-moz-placeholder {
  color: #adadad;
}
input::-moz-placeholder {
  color: #adadad;
}
input:-ms-input-placeholder {
  color: #adadad;
}

textarea::-webkit-input-placeholder {
  color: #adadad;
}
textarea:-moz-placeholder {
  color: #adadad;
}
textarea::-moz-placeholder {
  color: #adadad;
}
textarea:-ms-input-placeholder {
  color: #adadad;
}

/*---------------------------------------------*/
button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}

.txt1 {
  font-family: Poppins-Regular;
  font-size: 14px;
  line-height: 1.5;
  color: #666666;
}

.txt2 {
  font-family: Poppins-Regular;
  font-size: 14px;
  line-height: 1.5;
  color: #333333;
  text-transform: uppercase;
}

.bg1 {
  background-color: #3b5998;
}
.bg2 {
  background-color: #1da1f2;
}
.bg3 {
  background-color: #ea4335;
}
.btn-primary {
  /* background-color: transparent; */
  border-color: transparent;
}
.btn-primary:hover {
  background-color: #dbd966;
  border-color: transparent;
}

.table_recent {
  overflow-y: scroll !important;
  height: 70vh !important;
  width: auto !important;
}
/* width */
.table_recent::-webkit-scrollbar {
  width: 5px;
}
.react-datepicker-wrapper input{
  width:100%;
}
.react-datepicker-wrapper {
  border-bottom:1px solid !important;
  margin-right:20px;
}


/* Track */
.table_recent::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

/* Handle */
.table_recent::-webkit-scrollbar-thumb {
  background: #19a831;
  border-radius: 10px;
}
/* Handle on hover */
/* .table_recent::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
} */

/* .btn-hover:hover{
  background-color: #6233cd;
} */
.test_b {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
}
.place-add-btn-container {
  display: flex;
  justify-content: flex-end;
  margin-right: 3%;
  margin-top: 1%;
}

body {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.video-card {
  margin: 20px;
  align-self: flex-start;
  min-width: 344px;
}

.videos-card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.video__tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.tagComponent {
  display: flex;
  flex-direction: row;
  border: 1px solid rgb(187, 168, 168);
  padding: 5px 10px;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.tagComponent__tag {
  margin: 0;
  max-width: 100px;
  overflow: hidden;
}

.tagComponent__cancel {
  margin: 0;
  font-size: 10px;
  margin-left: 8px;
  cursor: pointer;
}

.sm-td {
  width: 200px;
}

.sm-td p {
  height: 200px;
  overflow-y: scroll;
}

.new-big-modal {
  height: 600px;
  overflow-y: scroll;
}
.loader-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}
.new-big-modal::-webkit-scrollbar {
  display: none;
}

.small-img {
  width: 50px;
  height: 50px;
  border-radius: 25px;
}

.faq-modal-image {
  width: 100%;
  object-fit: contain;
  height: 15vh;
  align-self: center;
}

.text-to-3-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.place-add-btn-container {
  display: flex;
  justify-content: flex-end;
  margin-right: 3%;
  margin-top: 1%;
}

.null-data-container {
  /* background-color: #19a831; */
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  margin: 0 20px;
}

.seo__tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.seo__image {
  object-fit: contain;
  width: 60%;
  height: 300px;
  margin-bottom: 10px;
}

/* modal privacy policy */
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.custom-addLang-modal .innerContainer {
  text-align: center;
}
.custom-addLang-modal input {
  /* border-color: #dce0eb;
    width: 240px;
    height: 38px;
    padding: 2px 12px;
    border-radius: 8px;
    font-size: 15px;
    margin-top: 10px; */

  margin: 15px 0;
  padding: 13px 10px;
  width: 80%;
  outline: none;
  border: 1px solid #bbb;
  border-radius: 8px;
  display: inline-block;
  box-sizing: border-box;
  transition: 0.2s ease all;
}
.custom-addLang-modal h2 {
  /* margin-bottom: 28px; */
  color: #fff;
  font-size: 28px;
  font-weight: 400;
}

.custom-addLang-modal .btn-save {
  color: #fff;
  background-color: #044d81;
  padding: 12px 22px;
  /* width: 299px; */
  width: 148px;
  border-radius: 8px;
}
.custom-addLang-modal .btn-cancel {
  color: rgb(17, 17, 17);
  background-color: #eceff1;
  padding: 12px 22px;
  border: none;
  /* width: 299px; */
  width: 148px;
  border-radius: 8px;
}
.custom-addLang-modal .btn-save:hover {
  color: rgb(19, 19, 19);
  background-color: #abc7db;
}

.custom-addLang-modal .btn-save:active {
  color: rgb(19, 19, 19);
  background-color: #abc7db;
}

.modal-Header {
  height: 90px;
  background-color: #175092;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
}

.custom-addLang-modal .addlang-modalBody {
  /* width: 200px; */
  /* height: 368px;
    padding: 50px 50px; */
  /* height: 256px; */
  height: auto;
  padding: 10px 50px;
}

.custom-addLang-modal .innerContainer {
  text-align: center;
}

.icon-upload-avatar {
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-upload-avatar > input[type="file"] {
  position: absolute;
  top: -16px;
  width: 40%;
  height: 100%;
  opacity: 0;
}
.icon-upload-avatar > span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.icon-upload-avatar > img {
  width: 40%;
}

.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #e3e6f0;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.footer-div {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
}

.modal-backdrop {
  opacity: 0 !important;
  z-index: 1 !important;
}
.custom-addLang-modal .addlang-modalBody input[type="text"]:focus {
  border: 1px solid #bbb !important;
}

.table-row-bold tr {
  font-weight: bold;
}

.table_recent::-webkit-scrollbar {
  width: 10px;
}
.table_recent {
  overflow: scroll;
  width: 50vw !important;
}
.table_recent::-webkit-scrollbar {
  width: 20px;
}
.table_recent {
  overflow: scroll;
  width: 50vw !important;
}

.table_recent::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.table_recent {
  overflow: scroll;
}
.table_recent::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.table_recent::-webkit-scrollbar-thumb {
  background: #34aadc;
  border-radius: 10px;
}

/* .table_recent::-webkit-scrollbar-thumb:hover {
  background: #b30000;
} */

.table-responsive {
  overflow-x: unset !important;
}

.search-bar {
  margin-right: inherit;
  width: 70%;
  margin-right: 10px;
}

.bar-container {
  display: flex;
  margin: auto;
  width: -webkit-fill-available;
  justify-content: center;
  align-items: center;
}
