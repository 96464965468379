body {
  overflow-x: hidden;
}

#content .card-header > .Section-Container > .col-md-6,
#content .card-header > .Section-Container > .col-md-12 {
  box-shadow: 0 0 6px 0px #0000002b;
  margin: 10px 0;
  padding: 20px;
}

.custom-sidebar .mouse-cursor {
  cursor: default;
}

.logo img {
  width: 100px;
  /* background-color: #fff; */
}

/* login */
.custom-alignment {
  margin-top: 100px;
}
.login .btn-primary-custom {
  color: #fff;
  background-color: #044d81;
}
.login .logo {
  width: 200px;
  margin-bottom: 12px;
}

.newMentorRequests .btn-approved {
  color: #fff;
  background-color: #9dcb3b;
}

.newMentorRequests .btn-pending {
  color: #fff;
  background-color: #044d81;
}
/* .newMentorRequests .btn-decline {
      color: #fff;
      background-color: #be1515 !important; 
  } */
.decline-color {
  background-color: #be1515 !important;
}
.newMentorRequests .pagination {
  float: right;
}

.Header .header-icon {
  font-size: 28px;
}

/* NewMentorRequestDetails screen*/
.newMentorRequestDetails .cover-photo img {
  width: 100%;
  background-size: cover;
  height: 350px;
  margin-bottom: 20px;
  border-radius: 8px;
}

.newMentorRequestDetails .profile img {
  width: 200px;
}
.newMentorRequestDetails .aboutMe-text {
  font-size: 18px;
  font-family: Aileron-Regular;
  font-weight: bold;
  margin: 0px;
}
.newMentorRequestDetails .aboutMe-para {
  font-family: Aileron-Regular;
  color: #969090;
}
.newMentorRequestDetails .location-lang {
  font-family: Aileron-Regular;
  color: #969090;
}

.newMentorRequestDetails .bio-text {
  font-size: 14px;
  font-family: Aileron-Regular;
  color: #969090;
}

.newMentorRequestDetails .keyword {
  font-size: 18px;
  font-family: Aileron-Regular;
  font-weight: bold;
  margin: 0px;
  margin-bottom: 12px;
  margin-top: 10px;
}

.newMentorRequestDetails .key-box {
  /* border-radius: 5px;
      padding: 10px 24px;
      color: #fff;
      background: yellowgreen;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 8px; */

  margin-left: 8px;
  align-items: center;
  padding: 10px 20px;
  font-family: Nexa Light;
  font-size: 12px;
  border: 1px solid #b7afaf;
  border-radius: 3px;
  color: #484646;
}
.newMentorRequestDetails .key-box p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.newMentorRequestDetails .Mentor-kind {
  font-size: 18px;
  font-family: Aileron-Regular;
  font-weight: bold;
  margin: 0px;
}

.newMentorRequestDetails .kind-text {
  font-size: 18px;
  font-family: Aileron-Regular;
  margin: 0px;
  color: #869494;
  font-weight: 400;
  margin-left: 14px;
}

.newMentorRequestDetails .pdf-container {
  font-size: 35px;
  font-family: Aileron-Regular;
  color: #868080;
  float: right;
  margin-right: 30px;
}

.newMentorRequestDetails .btn-approved {
  color: #fff;
  background-color: #9dcb3b;
  padding: 12px 22px;
}

.newMentorRequestDetails .btn-pending {
  color: #fff;
  background-color: #044d81;
  padding: 12px 22px;
  margin-left: 32px;
}

.newMentorRequestDetails .approved-pending {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 100px;
  float: right;
  margin-right: 40px;
}

/* ApprovedMentorDetails  screen*/
.ApprovedMentorDetails .cover-photo img {
  width: 100%;
  background-size: cover;
  height: 350px;
  margin-bottom: 20px;
  border-radius: 8px;
}
.ApprovedMentorDetails .profile img {
  width: 200px;
}
.ApprovedMentorDetails .rating {
  display: flex;
  margin-bottom: 10px;
  margin-top: -14px;
}
.ApprovedMentorDetails .rating-number {
  font-size: 16px;
  font-family: Aileron-Regular;
  margin: 4px 0 0 5px;
}
.ApprovedMentorDetails .lesson-mentees {
  margin-top: 5px;
}
.ApprovedMentorDetails .menteeRated-text {
  font-size: 13px;
  font-family: Aileron-Regular;
  color: #969090;
  margin: 0;
}

.ApprovedMentorDetails .bio-text {
  font-size: 14px;
  font-family: Aileron-Regular;
  color: #969090;
}

.ApprovedMentorDetails .aboutMe-text {
  font-size: 18px;
  font-family: Aileron-Regular;
  font-weight: bold;
  margin: 0px;
}
.ApprovedMentorDetails .aboutMe-para {
  font-family: Aileron-Regular;
  color: #969090;
}

.ApprovedMentorDetails .Mentor-kind {
  font-size: 18px;
  font-family: Aileron-Regular;
  font-weight: bold;
  margin: 0px;
}

.ApprovedMentorDetails .kind-text {
  font-size: 18px;
  font-family: Aileron-Regular;
  margin: 0px;
  color: #869494;
  font-weight: 400;
  margin-left: 14px;
}

.ApprovedMentorDetails .keyword {
  font-size: 18px;
  font-family: Aileron-Regular;
  font-weight: bold;
  margin: 0px;
  margin-bottom: 12px;
  margin-top: 10px;
}

.ApprovedMentorDetails .key-box {
  margin-left: 8px;
  align-items: center;
  padding: 10px 20px;
  font-family: Nexa Light;
  font-size: 12px;
  border: 1px solid #b7afaf;
  border-radius: 3px;
  color: #484646;
}
.ApprovedMentorDetails .key-box p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ApprovedMentorDetails .location-lang {
  font-family: Aileron-Regular;
  color: #969090;
}

/* Mentor Changes Details */
.MentorChangesDetails .cover-btn {
  float: right;
  width: 150px;
  margin-right: 1px;
  background-color: #044d81;
}
.MentorChangesDetails .profile-btn {
  width: 150px;
  margin-top: 20px;
  margin-right: 1px;
  background-color: #044d81;
}

.MentorChangesDetails .cover-photo img {
  width: 100%;
  background-size: cover;
  height: 350px;
  margin-bottom: 20px;
  border-radius: 8px;
}
.MentorChangesDetails .profile img {
  width: 200px;
}

.MentorChangesDetails .profile {
  text-align: center;
}

.MentorChangesDetails .editName-container {
  margin-top: 50px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.MentorChangesDetails .inputEdit-text {
  border-radius: 5px;
  width: 260px;
}

.MentorChangesDetails .display-name {
  font-weight: 700;
  font-size: 18px;
  font-family: Aileron-Regular;
  padding: 8px 22px;
  color: #777;
}

.MentorChangesDetails .save-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 100px;
  float: right;
  margin-right: 40px;
}

.MentorChangesDetails .btn-save {
  color: #fff;
  background-color: #044d81;
  padding: 12px 22px;
  margin-left: 32px;
  /* width: 200px; */
}

/* All Mentees  */
.table-img img {
  width: 50px;
}

/* Disputed Session */
.disputedSession .pagination {
  float: right;
}

/* Disputed Session Details  screen*/
.DisputedSessionDetail .profile img {
  width: 200px;
}

.DisputedSessionDetail .lesson-mentees {
  margin-top: 5px;
}
.DisputedSessionDetail .menteeRated-text {
  font-size: 13px;
  font-family: Aileron-Regular;
  color: #969090;
  margin: 0;
}

.DisputedSessionDetail .bio-text {
  font-size: 14px;
  font-family: Aileron-Regular;
  color: #969090;
}

.DisputedSessionDetail .location-lang {
  font-family: Aileron-Regular;
  color: #969090;
}

.DisputedSessionDetail h2 .name-type {
  font-size: 16px;
  font-weight: 300;
}

.DisputedSessionDetail .disputedSection {
  margin-top: 28px;
}
.DisputedSessionDetail .disputedSection-text {
  font-family: Aileron-Regular;
  color: #969090;
  font-size: 20px;
}
.DisputedSessionDetail .disputedSection-subText {
  font-family: Aileron-Regular;
  color: #969090;
  font-size: 15px;
}
.DisputedSessionDetail .button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.DisputedSessionDetail .btn-Fav-MenteeMentor {
  color: #fff !important;
  background-color: #044d81 !important;
  padding: 12px 22px !important;
  /* margin-left: 32px !important; */
}

/* Feature add lang */
.featureLang .addBtn i {
  font-size: 30px;
  color: #2aad12;
}

.featureLang .activeBtn {
  background-color: #044d81;
  padding: 10px 12px;
  width: 150px;
}
.featureLang .inactiveBtn {
  background-color: #be1515;
  padding: 10px 12px;
  width: 150px;
}

/* add modal */
.modal-content {
  border-radius: 18px !important;
}
.custom-addLang-modal .addlang-modalBody {
  /* width: 200px; */
  /* height: 368px;
      padding: 50px 50px; */
  /* height: 256px; */
  height: auto;
  padding: 10px 50px;
}

.custom-addLang-modal .innerContainer {
  text-align: center;
}
.custom-addLang-modal input {
  /* border-color: #dce0eb;
      width: 240px;
      height: 38px;
      padding: 2px 12px;
      border-radius: 8px;
      font-size: 15px;
      margin-top: 10px; */

  margin: 15px 0;
  padding: 13px 10px;
  width: 80%;
  outline: none;
  border: 1px solid #bbb;
  border-radius: 8px;
  display: inline-block;
  box-sizing: border-box;
  transition: 0.2s ease all;
}
.custom-addLang-modal h2 {
  /* margin-bottom: 28px; */
  color: #fff;
  font-size: 28px;
  font-weight: 400;
}

.custom-addLang-modal .btn-save {
  color: #fff;
  background-color: #044d81;
  padding: 12px 22px;
  /* width: 299px; */
  width: 148px;
  border-radius: 8px;
}
.custom-addLang-modal .btn-cancel {
  color: rgb(17, 17, 17);
  background-color: #eceff1;
  padding: 12px 22px;
  border: none;
  /* width: 299px; */
  width: 148px;
  border-radius: 8px;
}
.custom-addLang-modal .btn-save:hover {
  color: rgb(19, 19, 19);
  background-color: #abc7db;
}

.custom-addLang-modal .btn-save:active {
  color: rgb(19, 19, 19);
  background-color: #abc7db;
}

.addBtn i {
  margin-left: 14px;
  color: #a19e9e;
}

/* .addCertificateItems{
      background-color: brown;
      border-color: brown;
  } */

ul li {
  list-style: none;
}

.modal-label {
  margin-left: 12px;
}

.inputFields-container {
  display: block;
}

.inputBox {
  width: 340px;
  height: 55px;
  border-radius: 10px;
  border: 2px solid #949090;
  padding: 5px 15px;
  margin-left: 10px;
  margin-right: 20px;
  margin-top: 22px;
}
.DatePicker {
  margin-top: 22px;
  margin-right: 100px;
}

.DatePickerBox {
  width: 300px;
  height: 55px;
  padding: 5px 15px;
}

.datePicker-container {
  /* display: flex;
      justify-content: center;
      align-items: center; */
}

.table-image img {
  width: 58px;
}

.mechanic-icon i {
  font-size: 26px;
  padding-right: 18px;
  margin-bottom: 18px;
  margin-top: 12px;
}
.certificate-title {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 24px;
}
.certificateCard {
  width: 100%;
  background-size: cover;
  justify-content: center;
  align-items: center;
  display: flex;
}
.certificateCard > img {
  width: 80%;
}
.certificateCard > span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background: white; */
  color: #b30909;
  font-weight: bold;
  font-size: larger;
  /* padding: 30px 30px; */
  text-transform: uppercase;
}
.modal-Header {
  height: 90px;
  background-color: #175092;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
}

.darken-newMechanic {
  background-color: #3c70ac3d;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-div {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
}

.voucherTextArea {
  height: 100px !important;
}

.inputBoxAdmin {
  width: 340px;
  height: 55px;
  border-radius: 10px;
  border: 2px solid #949090;
  padding: 5px 15px;
  margin-left: 10px;
  margin-right: 20px;
  margin-top: 0px;
}
.Plans-textArea textarea {
  margin-top: 10px;
  width: 350px;
  padding: 6px 10px;
  height: 110px;
}

.custom-EditVoucher-modal {
  width: 4000px;
}
.voucher-modalBody {
  height: auto;
  padding: 10px 50px;
}

.modal-subTitle {
  color: #ababab;
  margin-top: 12px;
  margin-bottom: 20px;
}

.modal-Title {
  color: #ffffff;
}

.fieldText {
  margin-top: 10px;
}

.custom-addLang-modal textarea {
  margin: 15px 0;
  padding: 13px 10px;
  width: 80%;
  outline: none;
  border: 1px solid #bbb;
  border-radius: 8px;
  display: inline-block;
  box-sizing: border-box;
  transition: 0.2s ease all;
}

.pageTitle {
  text-align: center;
  color: #505050;
  padding-top: 24px;
  line-height: 1.1;
  font-size: 32px;
  text-transform: uppercase;
}

.page-Shortpara {
  color: #626060;
  padding-top: 10px;
  text-align: center;
}

.coverImage-Border {
  width: 80%;
  height: 200px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Image-Cover-Border {
  width: 100%;
  min-height: 200px;
  background-color: #fff;
  display: flex;
  justify-content: center;
}
.Image-Display-Border {
  width: 40%;
  height: 200px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Section-Container {
  background-color: white;
  padding: 10px;
  margin: 10px 0px;
  border-radius: 5px;
}
.pages-label-style {
  text-transform: uppercase;
}
.Section-Image-Display {
  width: 90%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.image-upload-avatar {
  /* background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADcAAAA3CAYAAACo29JGAAAABHNCSVQICAgIfAhkiAAABHtJREFUaEPtmktS20AQhiOoAnY4Jwg5QeAEMSeIfQLsDY8V5ATYJ4hZ8djYnCDmBJgTxDkB5gSxlkCB8/+qGVdrkOTRY2RVgaq0sDyP/qYf09OS9wnX5eXl6Ww2a3met8Xfri7MMcHdPTo6GriaQ47rAayDB6dlTKbneH19bZcB6F1cXNwrjXUx6cgV5MrKSgtj75UJSM3NOCHAdrGazuCEhfiYblPN6VSDy4DrAoy+HWjRpYkuBe7g4KADTTKoOAVcGhy15hpwqXCuAZcO5xKwEnCuAEuHQ4Zydnh4eBK1nxbtg6XBnZ+ft7CR99MkCUgudvb398dp+pjpVymbOCdFNtSDwMcphO1y20jRPtS0NM2lEVBkM+7gYEpbq6urX9IIptu+vLw8IJ2bZOnrHA4T0Ol/ZRFO9GnCrIZpxygDrgGhfqcVTLT3kTc2siTjzuFyQOXu+gFnsYTvN1paLI6TJs7N8urqahupEgtHtaIIVHFopMcjBILOxKynOIfLkE0sXAPA3SCvZBQOLlXi8LFdhBbQOVy/3689PT1FJrgLKWIaQEsDubEDooFnU3O7cA6XFaCIfh9wFqv4PrcC+tzz8/M3iwXK28Q3z2zOzbKgxNkKHNHSkw2dw6l9rmclXb5GE8C1SoXLJ2++3lGak2dLmPGdzQyVDijY9EcoS0wBMt/4BRRrK6O1tbVuu91mmzdXJeFSZkcE68G0+Q4idFUODmDH0Jb2dR/aG6BqNpARlSYKijraneAOIjq1vL6+3pRajIVjaoQ+eU7i5kKOsbo7Sb5ilP+uYXIncSanx1FRvYPffC0WmiMJrogaimTxIexWnLAAq0NDt6rDtRlBkxZFRfYRAWXRN9Es2QlVrEKOPBsbG+MkLcAcb2FidfPkYBMV2UZaGpLxr0zQK+FzUmtaMGl2gB5Jn2P29Pj4uAdN38nnAKT2vusFqgSceEcQMkcBPQXgLkHUUYzmu4071F5obwqz/lwJOPHRwZs6J/4bq4g4hVab0BZrqQTzacYReSm3hk2+44+FUyuUpq6f6B4wlSlO4WdRjfRHB1EvPpQJcjOXSXwkmPK9wDRxN5OiJcNrod+nmAmy8KvgZUzc/9zXoLF73T7pIwHtd2jbXaS5IssM9IPIRHyR5lDu0D6m+eY+aFqCgIvXnG0ILqIdBJpgHL5wSfK5oDzP7EX7oA4yUgaM9Q+/a4k+V4TQtmNYRMu5jxk+GIqWajP/w2DDiloloqUM4VjxHV0h0xU4aGho7nOszJnPMQ7TxUal9jkjyg2x6k1brYugNM+FddSthOYooJFbdqKOMHHAyhwZdGrWuWXa1cvbXtZteNTBEeanxamAGuOHBAT7iz513acymtMLYxxUg4MozOwmwud+qA9g6+xrgvFZ5eAoVMJJnKUFpl6hK+7blqrD6UIQ0ynzegDUEHcv7sMCfikbJKaqGDPK6zcF9Of5sUUfwj3f1Blw9NiLzoa6nWdEqQJkK2yIO0TMOVCWUYNKrwqljDq5BssiQESfCZ6xFpK7IPwfmWKV/wWSsjAAAAAASUVORK5CYII=); */
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  width: 100%;
  /* height: 60%; */
  height: 100%;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-upload-avatar > input[type="file"] {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.image-upload-avatar > span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.image-upload-avatar > img {
  top: 0;
  width: 100%;
}

.icon-upload-avatar {
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-upload-avatar > input[type="file"] {
  position: absolute;
  top: -16px;
  width: 40%;
  height: 100%;
  opacity: 0;
}
.icon-upload-avatar > span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.icon-upload-avatar > img {
  width: 40%;
}

.save-container {
  width: 144px;
  float: right;
  margin-bottom: 10px;
  margin-top: 12px;
}

.btn-activate {
  width: 200px;
}

.servicesItemAndTypeContainer {
  padding: 10px 0px;
  margin-bottom: 10px;
}
.serviceItemParent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  /* align-items: center; */
  border-bottom: 1px solid black;
  margin-bottom: 10px;
}

.serviceItemParent > * {
  display: inline-block;
  width: 15%;
  margin: 0 1%;
}

.serviceItemParent > p {
  width: 64%;
}

/* .serviceItemParent > button {
    background: #007bff !important;
  } */

.serviceItemParent > .btn-block {
  margin: 0 0 9px;
}

.MechanicScreenHeader > * {
  display: inline-block;
  margin: 0 1%;
}
.MechanicScreenHeader > Button {
  width: 15%;
}
.btn-group > * {
  margin: 1% 1%;
}
.btn-group > Button {
  width: 100%;
  margin: 1% 1%;
  max-width: 15%;
}

.CoverImageCms {
  height: 400px;
  object-fit: contain;
}
.DisplayImageCms {
  height: 300px;
  object-fit: contain;
}

.card-header h6 {
  padding-top: 0;
}

.alert-modal-Header {
  height: 100px;
  background-color: #175092;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: -1px;
  align-items: center;
  border-radius: 18px;
  flex-direction: column;
}
.alert-modal-Header > img {
  margin-top: 10px;
  object-fit: contain;
  height: 50px;
}
.alert-modal-Header > h2 {
  text-transform: uppercase;
}
.Alert-modal-message-container > p {
  text-transform: capitalize;
  color: black;
  padding: 46px 0px;
  font-size: larger;
}
.Alert-modal-message-container {
}

.section-faq-btn button {
  width: 40%;
  display: inline-block;
  vertical-align: text-bottom;
  margin: 5px;
  margin-top: 15px;
}

.FAQSectionDetailImage {
  height: 25px;
  object-fit: contain;
}

.Leaderlist i {
  color: #368ef5;
  font-size: 26px;
  padding-right: 20px;
}

.Leaderlist h4 {
  font-weight: 700;
  margin-bottom: 0;
}

.Leaders li {
  list-style-type: auto;
  font-weight: 700;
  font-size: 13px;
  line-height: 50px;
  margin: 10px 0px;
}

.Leaders li img {
  width: 18%;
  padding: 0px 10px;
  border-radius: 50%;
}

.Leaders span {
  color: darkgray;
  padding-left: 35px;
  float: right;
}

.Leaderlist {
  display: flex;
  align-items: center;
  padding-top: 15px;
  margin-bottom: 30px;
}

.Leaders {
  padding-top: 25px;
  box-shadow: 0 0 7px 2px #00000024;
  border-radius: 10px;
  padding: 25px;
  margin-top: 25px;
  width: 100%;
}

.paginationContainer {
  width: 100%;
  padding: 0px 15px;
}
.disabled-btn {
  background: #044d81bf !important;
}

.pagination {
  margin: 15px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  outline: none;
}
.pagination > .active > a {
  background-color: #007bff;
  border-color: #2790ff;
  color: #fff;
}
.pagination > li > a {
  border: 1px solid #2790ff;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #007bff;
  border-color: #2790ff;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #007bff;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}
