@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,800,900);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace, Spartan-Bold;
}

@font-face {
  font-family: "Spartan Regular";
  src: local("Spartan Regular"),
    url(/static/media/Spartan-Regular.01633446.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Spartan Bold";
  src: local("Spartan Bold"),
    url(/static/media/Spartan-Bold.cc267879.ttf) format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Spartan Light";
  src: local("Spartan Light"),
    url(/static/media/Spartan-Light.75e15ac7.ttf) format("truetype");
  font-weight: normal;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

@font-face {
  font-family: Poppins-Regular;
  src: url(/static/media/Poppins-Regular.1c701c9a.ttf);
}

@font-face {
  font-family: Poppins-Medium;
  src: url(/static/media/Poppins-Medium.b59f6a63.ttf);
}

@font-face {
  font-family: Poppins-Bold;
  src: url(/static/media/Poppins-Bold.e2abc8f5.ttf);
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url(/static/media/Poppins-SemiBold.5981b450.ttf);
}

/*//////////////////////////////////////////////////////////////////
  [ RESTYLE TAG ]*/

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  font-family: Poppins-Regular, sans-serif;
  overflow-x: hidden;
}

/*---------------------------------------------*/
a {
  font-family: Poppins-Regular;
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
  color: #a64bf4;
}

/*---------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

p {
  font-family: Poppins-Regular;
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}

/*---------------------------------------------*/
input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus,
input:focus {
  border-color: transparent !important;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}
input:focus:-moz-placeholder {
  color: transparent;
}
input:focus::-moz-placeholder {
  color: transparent;
}
input:focus:-ms-input-placeholder {
  color: transparent;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}
textarea:focus:-moz-placeholder {
  color: transparent;
}
textarea:focus::-moz-placeholder {
  color: transparent;
}
textarea:focus:-ms-input-placeholder {
  color: transparent;
}

input::-webkit-input-placeholder {
  color: #adadad;
}
input:-moz-placeholder {
  color: #adadad;
}
input::-moz-placeholder {
  color: #adadad;
}
input:-ms-input-placeholder {
  color: #adadad;
}

textarea::-webkit-input-placeholder {
  color: #adadad;
}
textarea:-moz-placeholder {
  color: #adadad;
}
textarea::-moz-placeholder {
  color: #adadad;
}
textarea:-ms-input-placeholder {
  color: #adadad;
}

/*---------------------------------------------*/
button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}

.txt1 {
  font-family: Poppins-Regular;
  font-size: 14px;
  line-height: 1.5;
  color: #666666;
}

.txt2 {
  font-family: Poppins-Regular;
  font-size: 14px;
  line-height: 1.5;
  color: #333333;
  text-transform: uppercase;
}

.bg1 {
  background-color: #3b5998;
}
.bg2 {
  background-color: #1da1f2;
}
.bg3 {
  background-color: #ea4335;
}
.btn-primary {
  /* background-color: transparent; */
  border-color: transparent;
}
.btn-primary:hover {
  background-color: #dbd966;
  border-color: transparent;
}

.table_recent {
  overflow-y: scroll !important;
  height: 70vh !important;
  width: auto !important;
}
/* width */
.table_recent::-webkit-scrollbar {
  width: 5px;
}
.react-datepicker-wrapper input{
  width:100%;
}
.react-datepicker-wrapper {
  border-bottom:1px solid !important;
  margin-right:20px;
}


/* Track */
.table_recent::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

/* Handle */
.table_recent::-webkit-scrollbar-thumb {
  background: #19a831;
  border-radius: 10px;
}
/* Handle on hover */
/* .table_recent::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
} */

/* .btn-hover:hover{
  background-color: #6233cd;
} */
.test_b {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
}
.place-add-btn-container {
  display: flex;
  justify-content: flex-end;
  margin-right: 3%;
  margin-top: 1%;
}

body {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.video-card {
  margin: 20px;
  align-self: flex-start;
  min-width: 344px;
}

.videos-card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.video__tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.tagComponent {
  display: flex;
  flex-direction: row;
  border: 1px solid rgb(187, 168, 168);
  padding: 5px 10px;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.tagComponent__tag {
  margin: 0;
  max-width: 100px;
  overflow: hidden;
}

.tagComponent__cancel {
  margin: 0;
  font-size: 10px;
  margin-left: 8px;
  cursor: pointer;
}

.sm-td {
  width: 200px;
}

.sm-td p {
  height: 200px;
  overflow-y: scroll;
}

.new-big-modal {
  height: 600px;
  overflow-y: scroll;
}
.loader-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}
.new-big-modal::-webkit-scrollbar {
  display: none;
}

.small-img {
  width: 50px;
  height: 50px;
  border-radius: 25px;
}

.faq-modal-image {
  width: 100%;
  object-fit: contain;
  height: 15vh;
  align-self: center;
}

.text-to-3-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.place-add-btn-container {
  display: flex;
  justify-content: flex-end;
  margin-right: 3%;
  margin-top: 1%;
}

.null-data-container {
  /* background-color: #19a831; */
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  margin: 0 20px;
}

.seo__tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.seo__image {
  object-fit: contain;
  width: 60%;
  height: 300px;
  margin-bottom: 10px;
}

/* modal privacy policy */
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.custom-addLang-modal .innerContainer {
  text-align: center;
}
.custom-addLang-modal input {
  /* border-color: #dce0eb;
    width: 240px;
    height: 38px;
    padding: 2px 12px;
    border-radius: 8px;
    font-size: 15px;
    margin-top: 10px; */

  margin: 15px 0;
  padding: 13px 10px;
  width: 80%;
  outline: none;
  border: 1px solid #bbb;
  border-radius: 8px;
  display: inline-block;
  box-sizing: border-box;
  transition: 0.2s ease all;
}
.custom-addLang-modal h2 {
  /* margin-bottom: 28px; */
  color: #fff;
  font-size: 28px;
  font-weight: 400;
}

.custom-addLang-modal .btn-save {
  color: #fff;
  background-color: #044d81;
  padding: 12px 22px;
  /* width: 299px; */
  width: 148px;
  border-radius: 8px;
}
.custom-addLang-modal .btn-cancel {
  color: rgb(17, 17, 17);
  background-color: #eceff1;
  padding: 12px 22px;
  border: none;
  /* width: 299px; */
  width: 148px;
  border-radius: 8px;
}
.custom-addLang-modal .btn-save:hover {
  color: rgb(19, 19, 19);
  background-color: #abc7db;
}

.custom-addLang-modal .btn-save:active {
  color: rgb(19, 19, 19);
  background-color: #abc7db;
}

.modal-Header {
  height: 90px;
  background-color: #175092;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
}

.custom-addLang-modal .addlang-modalBody {
  /* width: 200px; */
  /* height: 368px;
    padding: 50px 50px; */
  /* height: 256px; */
  height: auto;
  padding: 10px 50px;
}

.custom-addLang-modal .innerContainer {
  text-align: center;
}

.icon-upload-avatar {
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-upload-avatar > input[type="file"] {
  position: absolute;
  top: -16px;
  width: 40%;
  height: 100%;
  opacity: 0;
}
.icon-upload-avatar > span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.icon-upload-avatar > img {
  width: 40%;
}

.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #e3e6f0;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.footer-div {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
}

.modal-backdrop {
  opacity: 0 !important;
  z-index: 1 !important;
}
.custom-addLang-modal .addlang-modalBody input[type="text"]:focus {
  border: 1px solid #bbb !important;
}

.table-row-bold tr {
  font-weight: bold;
}

.table_recent::-webkit-scrollbar {
  width: 10px;
}
.table_recent {
  overflow: scroll;
  width: 50vw !important;
}
.table_recent::-webkit-scrollbar {
  width: 20px;
}
.table_recent {
  overflow: scroll;
  width: 50vw !important;
}

.table_recent::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.table_recent {
  overflow: scroll;
}
.table_recent::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.table_recent::-webkit-scrollbar-thumb {
  background: #34aadc;
  border-radius: 10px;
}

/* .table_recent::-webkit-scrollbar-thumb:hover {
  background: #b30000;
} */

.table-responsive {
  overflow-x: unset !important;
}

.search-bar {
  margin-right: inherit;
  width: 70%;
  margin-right: 10px;
}

.bar-container {
  display: flex;
  margin: auto;
  width: -webkit-fill-available;
  justify-content: center;
  align-items: center;
}

.sideBarNavLink {
  text-decoration: none !important;
}

.sideBarNavLink span {
  color: #22003b;
  opacity: 0.65;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.03em;
}

.sideBarNavLink img {
  width: 25px;
  object-fit: contain;
}

.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.container-login100-form-btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 50px;
}

.container-login100-form-btn-delete {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.wrap-login100 {
  width: 590px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  padding-left: 55px;
  padding-right: 55px;
  padding-top: 65px;
  padding-bottom: 54px;
  /* padding: 60px 30px; */
}

.wrap-login100-form-btn {
  width: 100%;
  display: block;
  position: relative;
  z-index: 1;
  border-radius: 25px;
  overflow: hidden;
  margin: 0 auto;

  box-shadow: 0 5px 30px 0px rgba(3, 216, 222, 0.2);
  -moz-box-shadow: 0 5px 30px 0px rgba(3, 216, 222, 0.2);
  -webkit-box-shadow: 0 5px 30px 0px rgba(3, 216, 222, 0.2);
  -o-box-shadow: 0 5px 30px 0px rgba(3, 216, 222, 0.2);
  -ms-box-shadow: 0 5px 30px 0px rgba(3, 216, 222, 0.2);
}

.wrap-login100-form-btn:hover .login100-form-bgbtn {
  left: 0;
}
.login100-form {
  width: 100%;
}

.login100-form-title {
  display: block;
  font-family: Poppins-Bold;
  font-size: 39px;
  color: #333333;
  line-height: 1.2;
  text-align: center;
  padding-bottom: 49px;
}

.login100-form-bgbtn {
  position: absolute;
  z-index: -1;
  width: 300%;
  height: 100%;
  background: #a64bf4;
  background: linear-gradient(right, #00dbde, #fc00ff, #00dbde, #fc00ff);
  top: 0;
  left: -100%;
  transition: all 0.4s;
}

.login100-form-btn {
  font-family: Poppins-Medium;
  font-size: 16px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 50px;
}

.wrap-input100 {
  width: 100%;
  position: relative;
  border-bottom: 2px solid #d9d9d9;
}
.label-input100 {
  font-family: Poppins-Regular;
  font-size: 14px;
  color: #333333;
  line-height: 1.5;
  padding-left: 7px;
}
.input100 {
  font-family: Poppins-Medium;
  font-size: 16px;
  color: #333333;
  line-height: 1.2;

  display: block;
  width: 100%;
  height: 55px;
  background: transparent;
  padding: 0 7px 0 43px;
}
.userNameContainer {
  margin-bottom: 23px;
}
.forgetBtn {
  padding-top: 8px;
  padding-bottom: 31px;
}
span.focus-input100 svg {
  fill: #adadad;
  margin-left: 5px;
}
.focus-input100 {
  position: absolute;
  display: block;
  width: 100%;
  /* height: 100%; */
  top: 40px;
  left: 0px;
  padding-bottom: 8px;
  pointer-events: none;
}

.focus-input100::after {
  content: attr(data-symbol);
  font-family: Material-Design-Iconic-Font;
  color: #adadad;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: calc(100% - 20px);
  bottom: 0;
  left: 0;
  padding-left: 13px;
  padding-top: 3px;
}

.focus-input100::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background: #7f7f7f;
  transition: all 0.4s;
}

.input100:focus + .focus-input100::before {
  width: 100%;
}

.has-val.input100 + .focus-input100::before {
  width: 100%;
}

.input100:focus + .focus-input100::after {
  color: #a64bf4;
}

.has-val.input100 + .focus-input100::after {
  color: #a64bf4;
}

.validate-input {
  position: relative;
}

@media (max-width: 992px) {
}

@media (max-width: 576px) {
  .wrap-login100 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.input-parentId {
  width: 200px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  height: 40px;
  padding: 0px 10px;
}
h1 {
  font-weight: bold;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.input-parentId:focus {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.4);
}

.add-cat-btn {
  background-color: #044d81;
  color: white;
  border-radius: 10px;
  height: 40px;
  padding: 0px 30px;
}

.update-cat-btn {
  background-color: #19a831;
  color: white;
  border-radius: 10px;
  height: 40px;
  padding: 0px 30px;
}


.delete-cat-btn {
  background-color: #e60000;
  color: white;
  border-radius: 10px;
  height: 40px;
  padding: 0px 30px;
}

.categroy-modal .addlang-modalBody {
  /* / width: 200px; / */
  /* height: 368px;
      padding: 50px 50px; */
  /* / height: 256px; / */
  height: auto;
  padding: 10px 50px;
}

.categroy-modal .innerContainer {
  text-align: center;
}
.categroy-modal input {
  /* border-color: #dce0eb;
      width: 240px;
      height: 38px;
      padding: 2px 12px;
      border-radius: 8px;
      font-size: 15px;
      margin-top: 10px; */

  margin: 15px 0;
  padding: 13px 10px;
  width: 80%;
  outline: none;
  border: 1px solid #bbb;
  border-radius: 8px;
  display: inline-block;
  box-sizing: border-box;
  transition: 0.2s ease all;
}
.categroy-modal h2 {
  /* / margin-bottom: 28px; / */
  color: #fff;
  font-size: 28px;
  font-weight: 400;
}

.categroy-modal .btn-save {
  color: #fff;
  background-color: #044d81;
  padding: 12px 22px;
  /* / width: 299px; / */
  width: 148px;
  border-radius: 8px;
}
.categroy-modal .btn-cancel {
  color: #044d81;
  background-color: #eceff1;
  padding: 12px 22px;
  border: 1px solid #044d81;
  /* / width: 299px; / */
  width: 148px;
  border-radius: 8px;
}
.categroy-modal .btn-save:hover {
  color: white;
  background-color: #044d81;
}

.categroy-modal .btn-save:active {
  color: rgb(19, 19, 19);
  background-color: #abc7db;
}

.categroy-modal textarea {
  margin: 15px 0;
  padding: 13px 10px;
  width: 80%;
  outline: none;
  border: 1px solid #bbb;
  border-radius: 8px;
  display: inline-block;
  box-sizing: border-box;
  transition: 0.2s ease all;
}

.modal-Header {
  height: 90px;
  background-color: #044d81;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: -1px;
  align-items: center;
  border-radius: 18px;
}

.categroy-modal .addlang-modalBody {
  height: auto;
  padding: 10px 50px;
}

.categroy-modal .innerContainer {
  text-align: center;
}

.categroy-modal .modal-content {
  border-radius: 20px !important;
}











.categroy-modal .icon-upload-avatar {
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.categroy-modal .icon-upload-avatar > input[type="file"] {
  position: absolute;
  top: -16px;
  width: 40%;
  height: 100%;
  opacity: 0;
}
.categroy-modal .icon-upload-avatar > span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.categroy-modal .icon-upload-avatar > img {
  width: 40%;
}
.pagination-button {
  background-color: #044d81 !important;
  color: white !important;
  padding: 10px 30px !important;
  border-radius: 6px !important;
}
.css-ahj2mt-MuiTypography-root {
  font-weight: bold !important;
}

.giveBorderLeft{
    border-left: 1px solid rgba(0,0,0,0.15);
}

.giveShadows{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.fieldSetHeaderContainer{
    background-color: white;
    width: 100%;
    padding: 30px 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-transform: uppercase;
}

.fieldSetMainContainer{
    background-color: white;
    width: 100%;
    /* margin: 10px 30px; */
    padding: 30px 0px;
    border-radius: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-right: 30px;
    margin-left: 30px;
}

.fieldSetItemContainer{
    padding: 2px 20px;
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    border-radius: 10px;
    width: 40%;
    cursor: pointer;
}
.blocked-users .block-btn {
  width: 120px;
  background-color: #044d81;
  color: white;
  border-radius: 7px;
  flex-wrap: wrap;
  padding: 9px;
}

.blocked-users .block-btn:hover {
  color: white;
}

.input-city {
    padding-left:20px;
  width: 300px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  font-size: 17px;
}

.update-city-modal.modal-content {
  width: 500px;
  background-color: #044d81 !important;
}

.update-city-modal-body {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #044d81;
  padding: 10px;
}

.update-city-modal .btn-save {
  color: #044d81;
  background-color: white;
  padding: 12px 22px;
  width: 170px;
  border: 1px solid #044d81 !important;
  border-radius: 20px;
}
.update-city-modal .btn-save:hover {
  border: 1px solid #044d81 !important;
  background-color: white;
}

.update-city-modal .btn-save:active {
  color: rgb(19, 19, 19);
  background-color: white;
  border: 1px solid #044d81 !important;
}

.confirmText {
  color: white;
}

.input-city:focus {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.4);
}

.saveBtn-Container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  background-color: #044d81 !important;
}

.fieldSetHeaderContainer{
    background-color: white;
    width: 100%;
    padding: 30px 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-transform: uppercase;
}

.fieldSetMainContainer{
    background-color: white;
    width: 96%;
    /* margin: 10px 30px; */
    padding: 30px 0px;
    border-radius: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-right: 30px;
    margin-left: 30px;
}

.fieldSetItemContainer{
    padding: 2px 20px;
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    border-radius: 10px;
    width: 40%;
    cursor: pointer;
}

.get-details-btn {
    background-color: #044d81;
    color: white;
    border-radius: 10px;
    height: 40px;
    padding: 0px 30px;
  }
.each-slide > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 350px;
  }
  
  .each-slide span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }

  .label {
      font-size: 20px;
      font-weight: bold;
      color:black
  }

  .value{
    font-size: 18px;
    font-weight: bold;
    color:rgba(0, 0, 0, 0.5)
  }

  .heading{
      font-weight: bold;
  }
.custom_container_recent {
  background: url("http://rybd.com/wp-content/uploads/2014/12/blue-polygon.png");
    max-width: 98%;
    overflow-y: scroll;
    height: 100vh;
}


/******************************
  
  Stati - minimal statistical cards
  
  *******************************/
.stati {
  background: #fff;
  height: 6em;
  padding: 1em;
  margin: 1em 0; /* Safari */
  transition: margin 0.5s ease, box-shadow 0.5s ease;
  box-shadow: 0px 0.2em 0.4em rgb(0, 0, 0, 0.8);
}
.stati:hover {
  margin-top: 0.5em;
  box-shadow: 0px 0.4em 0.5em rgb(0, 0, 0, 0.8);
}
.stati i {
  font-size: 3.5em;
}
.stati div {
  width: calc(100% - 3.5em);
  display: block;
  float: right;
  text-align: right;
}
.stati div b {
  font-size: 2.2em;
  width: 100%;
  padding-top: 0px;
  margin-top: -0.2em;
  margin-bottom: -0.2em;
  display: block;
}
.stati div span {
  font-size: 1em;
  width: 100%;
  color: rgb(0, 0, 0, 0.8) !important;
  display: block;
}

.stati.left div {
  float: left;
  text-align: left;
}

.stati.bg-turquoise {
  background: rgb(26, 188, 156);
  color: white;
}
.stati.bg-emerald {
  background: rgb(46, 204, 113);
  color: white;
}
.stati.bg-peter_river {
  background: rgb(52, 152, 219);
  color: white;
}
.stati.bg-amethyst {
  background: rgb(155, 89, 182);
  color: white;
}
.stati.bg-wet_asphalt {
  background: rgb(52, 73, 94);
  color: white;
}
.stati.bg-green_sea {
  background: rgb(22, 160, 133);
  color: white;
}
.stati.bg-nephritis {
  background: rgb(39, 174, 96);
  color: white;
}
.stati.bg-belize_hole {
  background: rgb(41, 128, 185);
  color: white;
}
.stati.bg-wisteria {
  background: rgb(142, 68, 173);
  color: white;
}
.stati.bg-midnight_blue {
  background: rgb(44, 62, 80);
  color: white;
}
.stati.bg-sun_flower {
  background: rgb(241, 196, 15);
  color: white;
}
.stati.bg-carrot {
  background: rgb(230, 126, 34);
  color: white;
}
.stati.bg-alizarin {
  background: rgb(231, 76, 60);
  color: white;
}
.stati.bg-clouds {
  background: rgb(236, 240, 241);
  color: white;
}
.stati.bg-concrete {
  background: rgb(149, 165, 166);
  color: white;
}
.stati.bg-orange {
  background: rgb(243, 156, 18);
  color: white;
}
.stati.bg-pumpkin {
  background: rgb(211, 84, 0);
  color: white;
}
.stati.bg-pomegranate {
  background: rgb(192, 57, 43);
  color: white;
}
.stati.bg-silver {
  background: rgb(189, 195, 199);
  color: white;
}
.stati.bg-asbestos {
  background: rgb(127, 140, 141);
  color: white;
}

.stati.turquoise {
  color: rgb(26, 188, 156);
}
.stati.emerald {
  color: rgb(46, 204, 113);
}
.stati.peter_river {
  color: rgb(52, 152, 219);
}
.stati.amethyst {
  color: rgb(155, 89, 182);
}
.stati.wet_asphalt {
  color: rgb(52, 73, 94);
}
.stati.green_sea {
  color: rgb(22, 160, 133);
}
.stati.nephritis {
  color: rgb(39, 174, 96);
}
.stati.belize_hole {
  color: rgb(41, 128, 185);
}
.stati.wisteria {
  color: rgb(142, 68, 173);
}
.stati.midnight_blue {
  color: rgb(44, 62, 80);
}
.stati.sun_flower {
  color: rgb(241, 196, 15);
}
.stati.carrot {
  color: rgb(230, 126, 34);
}
.stati.alizarin {
  color: rgb(231, 76, 60);
}
.stati.clouds {
  color: rgb(236, 240, 241);
}
.stati.concrete {
  color: rgb(149, 165, 166);
}
.stati.orange {
  color: rgb(243, 156, 18);
}
.stati.pumpkin {
  color: rgb(211, 84, 0);
}
.stati.pomegranate {
  color: rgb(192, 57, 43);
}
.stati.silver {
  color: rgb(189, 195, 199);
}
.stati.asbestos {
  color: rgb(127, 140, 141);
}

body {
  overflow-x: hidden;
}

#content .card-header > .Section-Container > .col-md-6,
#content .card-header > .Section-Container > .col-md-12 {
  box-shadow: 0 0 6px 0px #0000002b;
  margin: 10px 0;
  padding: 20px;
}

.custom-sidebar .mouse-cursor {
  cursor: default;
}

.logo img {
  width: 100px;
  /* background-color: #fff; */
}

/* login */
.custom-alignment {
  margin-top: 100px;
}
.login .btn-primary-custom {
  color: #fff;
  background-color: #044d81;
}
.login .logo {
  width: 200px;
  margin-bottom: 12px;
}

.newMentorRequests .btn-approved {
  color: #fff;
  background-color: #9dcb3b;
}

.newMentorRequests .btn-pending {
  color: #fff;
  background-color: #044d81;
}
/* .newMentorRequests .btn-decline {
      color: #fff;
      background-color: #be1515 !important; 
  } */
.decline-color {
  background-color: #be1515 !important;
}
.newMentorRequests .pagination {
  float: right;
}

.Header .header-icon {
  font-size: 28px;
}

/* NewMentorRequestDetails screen*/
.newMentorRequestDetails .cover-photo img {
  width: 100%;
  background-size: cover;
  height: 350px;
  margin-bottom: 20px;
  border-radius: 8px;
}

.newMentorRequestDetails .profile img {
  width: 200px;
}
.newMentorRequestDetails .aboutMe-text {
  font-size: 18px;
  font-family: Aileron-Regular;
  font-weight: bold;
  margin: 0px;
}
.newMentorRequestDetails .aboutMe-para {
  font-family: Aileron-Regular;
  color: #969090;
}
.newMentorRequestDetails .location-lang {
  font-family: Aileron-Regular;
  color: #969090;
}

.newMentorRequestDetails .bio-text {
  font-size: 14px;
  font-family: Aileron-Regular;
  color: #969090;
}

.newMentorRequestDetails .keyword {
  font-size: 18px;
  font-family: Aileron-Regular;
  font-weight: bold;
  margin: 0px;
  margin-bottom: 12px;
  margin-top: 10px;
}

.newMentorRequestDetails .key-box {
  /* border-radius: 5px;
      padding: 10px 24px;
      color: #fff;
      background: yellowgreen;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 8px; */

  margin-left: 8px;
  align-items: center;
  padding: 10px 20px;
  font-family: Nexa Light;
  font-size: 12px;
  border: 1px solid #b7afaf;
  border-radius: 3px;
  color: #484646;
}
.newMentorRequestDetails .key-box p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.newMentorRequestDetails .Mentor-kind {
  font-size: 18px;
  font-family: Aileron-Regular;
  font-weight: bold;
  margin: 0px;
}

.newMentorRequestDetails .kind-text {
  font-size: 18px;
  font-family: Aileron-Regular;
  margin: 0px;
  color: #869494;
  font-weight: 400;
  margin-left: 14px;
}

.newMentorRequestDetails .pdf-container {
  font-size: 35px;
  font-family: Aileron-Regular;
  color: #868080;
  float: right;
  margin-right: 30px;
}

.newMentorRequestDetails .btn-approved {
  color: #fff;
  background-color: #9dcb3b;
  padding: 12px 22px;
}

.newMentorRequestDetails .btn-pending {
  color: #fff;
  background-color: #044d81;
  padding: 12px 22px;
  margin-left: 32px;
}

.newMentorRequestDetails .approved-pending {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 100px;
  float: right;
  margin-right: 40px;
}

/* ApprovedMentorDetails  screen*/
.ApprovedMentorDetails .cover-photo img {
  width: 100%;
  background-size: cover;
  height: 350px;
  margin-bottom: 20px;
  border-radius: 8px;
}
.ApprovedMentorDetails .profile img {
  width: 200px;
}
.ApprovedMentorDetails .rating {
  display: flex;
  margin-bottom: 10px;
  margin-top: -14px;
}
.ApprovedMentorDetails .rating-number {
  font-size: 16px;
  font-family: Aileron-Regular;
  margin: 4px 0 0 5px;
}
.ApprovedMentorDetails .lesson-mentees {
  margin-top: 5px;
}
.ApprovedMentorDetails .menteeRated-text {
  font-size: 13px;
  font-family: Aileron-Regular;
  color: #969090;
  margin: 0;
}

.ApprovedMentorDetails .bio-text {
  font-size: 14px;
  font-family: Aileron-Regular;
  color: #969090;
}

.ApprovedMentorDetails .aboutMe-text {
  font-size: 18px;
  font-family: Aileron-Regular;
  font-weight: bold;
  margin: 0px;
}
.ApprovedMentorDetails .aboutMe-para {
  font-family: Aileron-Regular;
  color: #969090;
}

.ApprovedMentorDetails .Mentor-kind {
  font-size: 18px;
  font-family: Aileron-Regular;
  font-weight: bold;
  margin: 0px;
}

.ApprovedMentorDetails .kind-text {
  font-size: 18px;
  font-family: Aileron-Regular;
  margin: 0px;
  color: #869494;
  font-weight: 400;
  margin-left: 14px;
}

.ApprovedMentorDetails .keyword {
  font-size: 18px;
  font-family: Aileron-Regular;
  font-weight: bold;
  margin: 0px;
  margin-bottom: 12px;
  margin-top: 10px;
}

.ApprovedMentorDetails .key-box {
  margin-left: 8px;
  align-items: center;
  padding: 10px 20px;
  font-family: Nexa Light;
  font-size: 12px;
  border: 1px solid #b7afaf;
  border-radius: 3px;
  color: #484646;
}
.ApprovedMentorDetails .key-box p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ApprovedMentorDetails .location-lang {
  font-family: Aileron-Regular;
  color: #969090;
}

/* Mentor Changes Details */
.MentorChangesDetails .cover-btn {
  float: right;
  width: 150px;
  margin-right: 1px;
  background-color: #044d81;
}
.MentorChangesDetails .profile-btn {
  width: 150px;
  margin-top: 20px;
  margin-right: 1px;
  background-color: #044d81;
}

.MentorChangesDetails .cover-photo img {
  width: 100%;
  background-size: cover;
  height: 350px;
  margin-bottom: 20px;
  border-radius: 8px;
}
.MentorChangesDetails .profile img {
  width: 200px;
}

.MentorChangesDetails .profile {
  text-align: center;
}

.MentorChangesDetails .editName-container {
  margin-top: 50px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.MentorChangesDetails .inputEdit-text {
  border-radius: 5px;
  width: 260px;
}

.MentorChangesDetails .display-name {
  font-weight: 700;
  font-size: 18px;
  font-family: Aileron-Regular;
  padding: 8px 22px;
  color: #777;
}

.MentorChangesDetails .save-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 100px;
  float: right;
  margin-right: 40px;
}

.MentorChangesDetails .btn-save {
  color: #fff;
  background-color: #044d81;
  padding: 12px 22px;
  margin-left: 32px;
  /* width: 200px; */
}

/* All Mentees  */
.table-img img {
  width: 50px;
}

/* Disputed Session */
.disputedSession .pagination {
  float: right;
}

/* Disputed Session Details  screen*/
.DisputedSessionDetail .profile img {
  width: 200px;
}

.DisputedSessionDetail .lesson-mentees {
  margin-top: 5px;
}
.DisputedSessionDetail .menteeRated-text {
  font-size: 13px;
  font-family: Aileron-Regular;
  color: #969090;
  margin: 0;
}

.DisputedSessionDetail .bio-text {
  font-size: 14px;
  font-family: Aileron-Regular;
  color: #969090;
}

.DisputedSessionDetail .location-lang {
  font-family: Aileron-Regular;
  color: #969090;
}

.DisputedSessionDetail h2 .name-type {
  font-size: 16px;
  font-weight: 300;
}

.DisputedSessionDetail .disputedSection {
  margin-top: 28px;
}
.DisputedSessionDetail .disputedSection-text {
  font-family: Aileron-Regular;
  color: #969090;
  font-size: 20px;
}
.DisputedSessionDetail .disputedSection-subText {
  font-family: Aileron-Regular;
  color: #969090;
  font-size: 15px;
}
.DisputedSessionDetail .button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.DisputedSessionDetail .btn-Fav-MenteeMentor {
  color: #fff !important;
  background-color: #044d81 !important;
  padding: 12px 22px !important;
  /* margin-left: 32px !important; */
}

/* Feature add lang */
.featureLang .addBtn i {
  font-size: 30px;
  color: #2aad12;
}

.featureLang .activeBtn {
  background-color: #044d81;
  padding: 10px 12px;
  width: 150px;
}
.featureLang .inactiveBtn {
  background-color: #be1515;
  padding: 10px 12px;
  width: 150px;
}

/* add modal */
.modal-content {
  border-radius: 18px !important;
}
.custom-addLang-modal .addlang-modalBody {
  /* width: 200px; */
  /* height: 368px;
      padding: 50px 50px; */
  /* height: 256px; */
  height: auto;
  padding: 10px 50px;
}

.custom-addLang-modal .innerContainer {
  text-align: center;
}
.custom-addLang-modal input {
  /* border-color: #dce0eb;
      width: 240px;
      height: 38px;
      padding: 2px 12px;
      border-radius: 8px;
      font-size: 15px;
      margin-top: 10px; */

  margin: 15px 0;
  padding: 13px 10px;
  width: 80%;
  outline: none;
  border: 1px solid #bbb;
  border-radius: 8px;
  display: inline-block;
  box-sizing: border-box;
  transition: 0.2s ease all;
}
.custom-addLang-modal h2 {
  /* margin-bottom: 28px; */
  color: #fff;
  font-size: 28px;
  font-weight: 400;
}

.custom-addLang-modal .btn-save {
  color: #fff;
  background-color: #044d81;
  padding: 12px 22px;
  /* width: 299px; */
  width: 148px;
  border-radius: 8px;
}
.custom-addLang-modal .btn-cancel {
  color: rgb(17, 17, 17);
  background-color: #eceff1;
  padding: 12px 22px;
  border: none;
  /* width: 299px; */
  width: 148px;
  border-radius: 8px;
}
.custom-addLang-modal .btn-save:hover {
  color: rgb(19, 19, 19);
  background-color: #abc7db;
}

.custom-addLang-modal .btn-save:active {
  color: rgb(19, 19, 19);
  background-color: #abc7db;
}

.addBtn i {
  margin-left: 14px;
  color: #a19e9e;
}

/* .addCertificateItems{
      background-color: brown;
      border-color: brown;
  } */

ul li {
  list-style: none;
}

.modal-label {
  margin-left: 12px;
}

.inputFields-container {
  display: block;
}

.inputBox {
  width: 340px;
  height: 55px;
  border-radius: 10px;
  border: 2px solid #949090;
  padding: 5px 15px;
  margin-left: 10px;
  margin-right: 20px;
  margin-top: 22px;
}
.DatePicker {
  margin-top: 22px;
  margin-right: 100px;
}

.DatePickerBox {
  width: 300px;
  height: 55px;
  padding: 5px 15px;
}

.datePicker-container {
  /* display: flex;
      justify-content: center;
      align-items: center; */
}

.table-image img {
  width: 58px;
}

.mechanic-icon i {
  font-size: 26px;
  padding-right: 18px;
  margin-bottom: 18px;
  margin-top: 12px;
}
.certificate-title {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 24px;
}
.certificateCard {
  width: 100%;
  background-size: cover;
  justify-content: center;
  align-items: center;
  display: flex;
}
.certificateCard > img {
  width: 80%;
}
.certificateCard > span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background: white; */
  color: #b30909;
  font-weight: bold;
  font-size: larger;
  /* padding: 30px 30px; */
  text-transform: uppercase;
}
.modal-Header {
  height: 90px;
  background-color: #175092;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
}

.darken-newMechanic {
  background-color: #3c70ac3d;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-div {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
}

.voucherTextArea {
  height: 100px !important;
}

.inputBoxAdmin {
  width: 340px;
  height: 55px;
  border-radius: 10px;
  border: 2px solid #949090;
  padding: 5px 15px;
  margin-left: 10px;
  margin-right: 20px;
  margin-top: 0px;
}
.Plans-textArea textarea {
  margin-top: 10px;
  width: 350px;
  padding: 6px 10px;
  height: 110px;
}

.custom-EditVoucher-modal {
  width: 4000px;
}
.voucher-modalBody {
  height: auto;
  padding: 10px 50px;
}

.modal-subTitle {
  color: #ababab;
  margin-top: 12px;
  margin-bottom: 20px;
}

.modal-Title {
  color: #ffffff;
}

.fieldText {
  margin-top: 10px;
}

.custom-addLang-modal textarea {
  margin: 15px 0;
  padding: 13px 10px;
  width: 80%;
  outline: none;
  border: 1px solid #bbb;
  border-radius: 8px;
  display: inline-block;
  box-sizing: border-box;
  transition: 0.2s ease all;
}

.pageTitle {
  text-align: center;
  color: #505050;
  padding-top: 24px;
  line-height: 1.1;
  font-size: 32px;
  text-transform: uppercase;
}

.page-Shortpara {
  color: #626060;
  padding-top: 10px;
  text-align: center;
}

.coverImage-Border {
  width: 80%;
  height: 200px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Image-Cover-Border {
  width: 100%;
  min-height: 200px;
  background-color: #fff;
  display: flex;
  justify-content: center;
}
.Image-Display-Border {
  width: 40%;
  height: 200px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Section-Container {
  background-color: white;
  padding: 10px;
  margin: 10px 0px;
  border-radius: 5px;
}
.pages-label-style {
  text-transform: uppercase;
}
.Section-Image-Display {
  width: 90%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.image-upload-avatar {
  /* background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADcAAAA3CAYAAACo29JGAAAABHNCSVQICAgIfAhkiAAABHtJREFUaEPtmktS20AQhiOoAnY4Jwg5QeAEMSeIfQLsDY8V5ATYJ4hZ8djYnCDmBJgTxDkB5gSxlkCB8/+qGVdrkOTRY2RVgaq0sDyP/qYf09OS9wnX5eXl6Ww2a3met8Xfri7MMcHdPTo6GriaQ47rAayDB6dlTKbneH19bZcB6F1cXNwrjXUx6cgV5MrKSgtj75UJSM3NOCHAdrGazuCEhfiYblPN6VSDy4DrAoy+HWjRpYkuBe7g4KADTTKoOAVcGhy15hpwqXCuAZcO5xKwEnCuAEuHQ4Zydnh4eBK1nxbtg6XBnZ+ft7CR99MkCUgudvb398dp+pjpVymbOCdFNtSDwMcphO1y20jRPtS0NM2lEVBkM+7gYEpbq6urX9IIptu+vLw8IJ2bZOnrHA4T0Ol/ZRFO9GnCrIZpxygDrgGhfqcVTLT3kTc2siTjzuFyQOXu+gFnsYTvN1paLI6TJs7N8urqahupEgtHtaIIVHFopMcjBILOxKynOIfLkE0sXAPA3SCvZBQOLlXi8LFdhBbQOVy/3689PT1FJrgLKWIaQEsDubEDooFnU3O7cA6XFaCIfh9wFqv4PrcC+tzz8/M3iwXK28Q3z2zOzbKgxNkKHNHSkw2dw6l9rmclXb5GE8C1SoXLJ2++3lGak2dLmPGdzQyVDijY9EcoS0wBMt/4BRRrK6O1tbVuu91mmzdXJeFSZkcE68G0+Q4idFUODmDH0Jb2dR/aG6BqNpARlSYKijraneAOIjq1vL6+3pRajIVjaoQ+eU7i5kKOsbo7Sb5ilP+uYXIncSanx1FRvYPffC0WmiMJrogaimTxIexWnLAAq0NDt6rDtRlBkxZFRfYRAWXRN9Es2QlVrEKOPBsbG+MkLcAcb2FidfPkYBMV2UZaGpLxr0zQK+FzUmtaMGl2gB5Jn2P29Pj4uAdN38nnAKT2vusFqgSceEcQMkcBPQXgLkHUUYzmu4071F5obwqz/lwJOPHRwZs6J/4bq4g4hVab0BZrqQTzacYReSm3hk2+44+FUyuUpq6f6B4wlSlO4WdRjfRHB1EvPpQJcjOXSXwkmPK9wDRxN5OiJcNrod+nmAmy8KvgZUzc/9zXoLF73T7pIwHtd2jbXaS5IssM9IPIRHyR5lDu0D6m+eY+aFqCgIvXnG0ILqIdBJpgHL5wSfK5oDzP7EX7oA4yUgaM9Q+/a4k+V4TQtmNYRMu5jxk+GIqWajP/w2DDiloloqUM4VjxHV0h0xU4aGho7nOszJnPMQ7TxUal9jkjyg2x6k1brYugNM+FddSthOYooJFbdqKOMHHAyhwZdGrWuWXa1cvbXtZteNTBEeanxamAGuOHBAT7iz513acymtMLYxxUg4MozOwmwud+qA9g6+xrgvFZ5eAoVMJJnKUFpl6hK+7blqrD6UIQ0ynzegDUEHcv7sMCfikbJKaqGDPK6zcF9Of5sUUfwj3f1Blw9NiLzoa6nWdEqQJkK2yIO0TMOVCWUYNKrwqljDq5BssiQESfCZ6xFpK7IPwfmWKV/wWSsjAAAAAASUVORK5CYII=); */
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  width: 100%;
  /* height: 60%; */
  height: 100%;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-upload-avatar > input[type="file"] {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.image-upload-avatar > span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.image-upload-avatar > img {
  top: 0;
  width: 100%;
}

.icon-upload-avatar {
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-upload-avatar > input[type="file"] {
  position: absolute;
  top: -16px;
  width: 40%;
  height: 100%;
  opacity: 0;
}
.icon-upload-avatar > span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.icon-upload-avatar > img {
  width: 40%;
}

.save-container {
  width: 144px;
  float: right;
  margin-bottom: 10px;
  margin-top: 12px;
}

.btn-activate {
  width: 200px;
}

.servicesItemAndTypeContainer {
  padding: 10px 0px;
  margin-bottom: 10px;
}
.serviceItemParent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  /* align-items: center; */
  border-bottom: 1px solid black;
  margin-bottom: 10px;
}

.serviceItemParent > * {
  display: inline-block;
  width: 15%;
  margin: 0 1%;
}

.serviceItemParent > p {
  width: 64%;
}

/* .serviceItemParent > button {
    background: #007bff !important;
  } */

.serviceItemParent > .btn-block {
  margin: 0 0 9px;
}

.MechanicScreenHeader > * {
  display: inline-block;
  margin: 0 1%;
}
.MechanicScreenHeader > Button {
  width: 15%;
}
.btn-group > * {
  margin: 1% 1%;
}
.btn-group > Button {
  width: 100%;
  margin: 1% 1%;
  max-width: 15%;
}

.CoverImageCms {
  height: 400px;
  object-fit: contain;
}
.DisplayImageCms {
  height: 300px;
  object-fit: contain;
}

.card-header h6 {
  padding-top: 0;
}

.alert-modal-Header {
  height: 100px;
  background-color: #175092;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: -1px;
  align-items: center;
  border-radius: 18px;
  flex-direction: column;
}
.alert-modal-Header > img {
  margin-top: 10px;
  object-fit: contain;
  height: 50px;
}
.alert-modal-Header > h2 {
  text-transform: uppercase;
}
.Alert-modal-message-container > p {
  text-transform: capitalize;
  color: black;
  padding: 46px 0px;
  font-size: larger;
}
.Alert-modal-message-container {
}

.section-faq-btn button {
  width: 40%;
  display: inline-block;
  vertical-align: text-bottom;
  margin: 5px;
  margin-top: 15px;
}

.FAQSectionDetailImage {
  height: 25px;
  object-fit: contain;
}

.Leaderlist i {
  color: #368ef5;
  font-size: 26px;
  padding-right: 20px;
}

.Leaderlist h4 {
  font-weight: 700;
  margin-bottom: 0;
}

.Leaders li {
  list-style-type: auto;
  font-weight: 700;
  font-size: 13px;
  line-height: 50px;
  margin: 10px 0px;
}

.Leaders li img {
  width: 18%;
  padding: 0px 10px;
  border-radius: 50%;
}

.Leaders span {
  color: darkgray;
  padding-left: 35px;
  float: right;
}

.Leaderlist {
  display: flex;
  align-items: center;
  padding-top: 15px;
  margin-bottom: 30px;
}

.Leaders {
  padding-top: 25px;
  box-shadow: 0 0 7px 2px #00000024;
  border-radius: 10px;
  padding: 25px;
  margin-top: 25px;
  width: 100%;
}

.paginationContainer {
  width: 100%;
  padding: 0px 15px;
}
.disabled-btn {
  background: #044d81bf !important;
}

.pagination {
  margin: 15px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  outline: none;
}
.pagination > .active > a {
  background-color: #007bff;
  border-color: #2790ff;
  color: #fff;
}
.pagination > li > a {
  border: 1px solid #2790ff;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #007bff;
  border-color: #2790ff;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #007bff;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

/* Please ❤ this if you like it! */
*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.7;
  color: #1f2029;
  background-color: #fff;
  overflow: hidden;
  background-image: url("https://assets.codepen.io/1462889/back-page.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 101%;
}
p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.7;
  color: #1f2029;
}

.section {
  position: relative;
  width: 100%;
  display: block;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.full-height {
  min-height: 100vh;
}

[type="checkbox"]:checked,
[type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.modal-btn:checked + label,
.modal-btn:not(:checked) + label {
  position: relative;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 2;
  height: 50px;
  transition: all 200ms linear;
  border-radius: 4px;
  width: 240px;
  letter-spacing: 1px;
  display: inline-flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  text-align: center;
  align-self: center;
  border: none;
  cursor: pointer;
  background-color: #102770;
  color: #ffeba7;
  box-shadow: 0 12px 35px 0 rgba(16, 39, 112, 0.25);
}
.modal-btn:not(:checked) + label:hover {
  background-color: #ffeba7;
  color: #102770;
}
.modal-btn:checked + label .uil,
.modal-btn:not(:checked) + label .uil {
  margin-left: 10px;
  font-size: 18px;
}
.modal-btn:checked + label:after,
.modal-btn:not(:checked) + label:after {
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 110;
  width: 40px;
  border-radius: 3px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 18px;
  background-color: #ffeba7;
  color: #102770;
  font-family: "unicons";
  content: "\eac6";
  box-shadow: 0 12px 25px 0 rgba(16, 39, 112, 0.25);
  transition: all 200ms linear;
  opacity: 0;
  pointer-events: none;
  transform: translateY(20px);
}
.modal-btn:checked + label:hover:after,
.modal-btn:not(:checked) + label:hover:after {
  background-color: #102770;
  color: #ffeba7;
}
.modal-btn:checked + label:after {
  transition: opacity 300ms 300ms ease, transform 300ms 300ms ease,
    background-color 250ms linear, color 250ms linear;
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}
.modal {
  position: fixed;
  display: block;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow-x: hidden;
  background-color: rgba(31, 32, 41, 0.75);
  pointer-events: none;
  opacity: 0;
  transition: opacity 250ms 700ms ease;
}
.modal-btn:checked ~ .modal {
  pointer-events: auto;
  opacity: 1;
  transition: all 300ms ease-in-out;
}
.modal-wrap {
  position: relative;
  display: block;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  overflow: hidden;
  padding-bottom: 20px;
  background-color: #fff;
  align-self: center;
  box-shadow: 0 12px 25px 0 rgba(199, 175, 189, 0.25);
  opacity: 0;
  transform: scale(0.6);
  transition: opacity 250ms 250ms ease, transform 300ms 250ms ease;
}
.modal-wrap img {
  display: block;
  width: 100%;
  height: auto;
}
.modal-wrap p {
  padding: 20px 30px 0 30px;
}
.modal-btn:checked ~ .modal .modal-wrap {
  opacity: 1;
  transform: scale(1);
  transition: opacity 250ms 500ms ease, transform 350ms 500ms ease;
}

.logo {
  position: absolute;
  top: 25px;
  left: 25px;
  display: block;
  z-index: 1000;
  transition: all 250ms linear;
}
.logo img {
  height: 26px;
  width: auto;
  display: block;
  filter: brightness(10%);
  transition: filter 250ms 700ms linear;
}
.modal-btn:checked ~ .logo img {
  filter: brightness(100%);
  transition: all 250ms linear;
}

@media screen and (max-width: 500px) {
  .modal-wrap {
    width: calc(100% - 40px);
    padding-bottom: 15px;
  }
  .modal-wrap p {
    padding: 15px 20px 0 20px;
  }
}

.DemoAccountLoginCredentials_mainContainer__1Dywy {
  margin: 20px auto;
  background: white;
  border-radius: 20px;
  box-shadow: 7px 3px 21px #0000003d;
  padding: 15px;
}

.DemoAccountLoginCredentials_mainContainer__1Dywy h5 {
  margin-bottom: 10px;
}

.DemoAccountLoginCredentials_mainContainer__1Dywy table {
  width: 100%;
  border-collapse: collapse;
}

.DemoAccountLoginCredentials_mainContainer__1Dywy tr td,
.DemoAccountLoginCredentials_mainContainer__1Dywy tr th {
  padding: 10px;
}

.DemoAccountLoginCredentials_mainContainer__1Dywy table thead th {
  background-color: #007bffab;
  color: #fff;
}

.DemoAccountLoginCredentials_cp__2zhAE {
  cursor: pointer;
}

